<template>
  <div>
    <div class="title">
      {{ $t('serviceProvider.settingsServices.settingsServices') }}
    </div>

    <Header
      :sorting-options="tableSorting.options"
      :search-label="$t('serviceProvider.settingsServices.searchServices')"
      @sort="option => (tableSorting.selected = option)"
      @search="inp => (search = inp)"
    >
      <template #button-1>
        <template v-if="!selected">
          <Button :orange="true" @click="proceedToCreate">
            <PlusIcon width="22px" height="22px" />
            {{ $t('serviceProvider.settingsServices.createService') }}
          </Button>
        </template>
        <template v-else>
          <Button :orange="true" @click="serviceEdit">
            {{ $t('serviceProvider.settingsServices.edit') }}
          </Button>
        </template>
      </template>

      <template #button-2>
        <template v-if="!selected">
          <Button :orange-secondary="true" @click="modalActivate('category')">
            <PlusIcon width="22px" height="22px" color="#e58413" />
            {{ $t('serviceProvider.settingsServices.createCategory') }}
          </Button>
        </template>
        <template v-else>
          <Button :orange-secondary="true" @click="serviceDelete">
            {{ $t('serviceProvider.settingsServices.delete') }}
          </Button>
        </template>
      </template>
    </Header>

    <Table
      style="margin-bottom: 1.5rem"
      :data="services"
      :keys="tableKeys"
      :column-width="tableColumnWidth"
      :selectable="true"
      @itemSelect="selectItem"
    >
      <template #cell-images="{ value }">
        <div>
          <img
            :src="value.images[0] || require('@/assets/pics/logo_black.png')"
            class="image"
          />
        </div>
      </template>
      <template #cell-category="{ cell }">
        <div>
          {{ cell ? cell.name : '' }}
        </div>
      </template>
      <template #cell-duration="{ value }">
        <span>{{ value.duration }} Minuten</span>
      </template>
      <template #cell-price="{ value }">
        <span>{{ value.price | EUR }}</span>
      </template>
    </Table>

    <!-- create category modal -->
    <Modal :open="modalActive">
      <component :is="active" @close="toggleModal"> </component>
    </Modal>
  </div>
</template>

<script>
import { sortBy, filter } from 'lodash';
import store from '@/store';
import Table from '@/components/Staff/TableDivCustom';
import Header from '@/components/Staff/TableHeaderCustom';
import Modal from '@/components/Modal/Modal';
import ModalServicesCategory from '@/components/Services/ModalServicesCategory';
import CheckboxElement from '@/components/FormControl/CheckboxElement';
import Button from '@/components/FormControl/ButtonElement';
import PlusIcon from '@/components/Svgs/PlusIcon';

export default {
  name: 'SettingsServices',

  components: {
    Table,
    Header,
    Modal,
    CheckboxElement,
    Button,
    PlusIcon,
  },

  data() {
    return {
      selectionActive: false,
      modalActive: false,
      active: false,
      selected: false,
      editActive: false,
      search: '',

      modalComponents: {
        category: ModalServicesCategory,
      },
      tableSorting: {
        selected: false,
        options: [
          {
            label: this.$t('serviceProvider.settingsServices.category'),
            value: 'category',
          },
          {
            label: this.$t('serviceProvider.settingsServices.duration'),
            value: 'duration',
          },
          {
            label: this.$t('serviceProvider.settingsServices.price'),
            value: 'price',
          },
        ],
      },
      tableKeys: [
        {
          key: 'images',
          label: 'serviceProvider.settingsServices.picture',
          width: '50px',
        },
        {
          key: 'name',
          label: 'serviceProvider.settingsServices.name',
          width: '100px',
        },
        {
          key: 'description',
          label: 'serviceProvider.settingsServices.description',
          width: '100px',
        },
        {
          key: 'category',
          label: 'serviceProvider.settingsServices.category',
          width: '150px',
        },
        {
          key: 'duration',
          label: 'serviceProvider.settingsServices.duration',
          width: '100px',
        },
        {
          key: 'price',
          label: 'serviceProvider.settingsServices.price',
          width: '70px',
        },
      ],
      tableColumnWidth: [
        { key: 'images', width: '80px', fixed: true },
        { key: 'name', width: '200px', fixed: false },
        { key: 'description', width: '150px', fixed: false },
        { key: 'category', width: '150px', fixed: false },
        { key: 'duration', width: '150px', fixed: false },
        { key: 'price', width: '150px', fixed: false },
      ],
    };
  },
  created() {
    store.dispatch('fetchCompanyServices');
  },
  methods: {
    toggleModal() {
      this.modalActive = !this.modalActive;
    },
    modalActivate(type) {
      this.active = this.modalComponents[type];
      this.toggleModal();
    },
    selectItem(item) {
      this.selected = this.selected === item ? false : item;
    },
    serviceDelete() {
      store.dispatch('deleteService', this.selected);
    },
    serviceEdit() {
      this.proceedToCreate();
    },
    proceedToCreate() {
      if (this.selected) {
        this.$router.push({
          name: 'SettingsServicesCreate',
          params: { service: this.selected },
        });
      } else {
        this.$router.push({ name: 'SettingsServicesCreate' });
      }
    },
  },
  computed: {
    loading() {
      return store.getters.loading;
    },
    services() {
      if (this.search.length) {
        return filter(store.getters.companyServices, el => {
          return el.name.toLowerCase().includes(this.search.toLowerCase());
        });
      }
      if (this.tableSorting.selected) {
        return sortBy(store.getters.companyServices, el => {
          return el[this.tableSorting.selected];
        });
      }
      return store.getters.companyServices;
    },
  },
};
</script>

<style lang="scss" scoped>
.image {
  width: 48px;
  height: 48px;
  border-radius: 10px;
  object-fit: cover;
}
</style>
