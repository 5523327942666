<template>
  <label class="checkbox-container">
    <input
      class="checkbox"
      type="checkbox"
      :value="value"
      @change="handleChange"
      :disabled="readOnly"
      ref="checkboxInput"
    />
    <div
      class="checkbox-custom empty"
      :class="{
        orange: value && !readOnly,
        grey: (checked && readOnly) || (checked && color === 'grey'),
        disabled: readOnly,
      }"
    >
      <CheckboxIcon v-if="value" />
    </div>
  </label>
</template>

<script>
import CheckboxIcon from '../Svgs/CheckboxIcon.vue';

export default {
  components: {
    CheckboxIcon,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: 'orange',
    },
  },
  data() {
    return {
      checked: false,
    };
  },
  methods: {
    handleChange() {
      this.checked = !this.checked;
      // console.log('checked :>> ', this.checked);
      this.$emit('input', this.checked);
    },
  },
  watch: {
    value(a, b) {},
  },
};
</script>

<style lang="scss" scoped>
.checkbox-container {
  position: relative;
}
.checkbox {
  position: absolute;
  opacity: 0;
}
.checkbox-custom {
  cursor: pointer;
  position: relative;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  border: $hty-border;
  border-radius: 4px;

  > svg {
    position: absolute;
    left: 3px;
    top: 5px;
  }

  &.disabled {
    cursor: not-allowed;
    background-color: $hty-grey-border;
    pointer-events: none;
  }

  &.empty {
    border-color: $hty-grey-border;
  }

  &.orange {
    background-color: $hty-orange;
    border-color: $hty-orange;
  }

  &.grey {
    background-color: $hty-grey;
  }
}
</style>
