<template>
  <OutsideClickContainer @outsideClick="debounceClose" active>
    <div :class="['staff-side-preview', { active: isActive }]">
      <div class="content-wrap" v-if="profile">
        <div @click="debounceClose" class="icon">
          <XIcon />
        </div>

        <img
          class="image"
          :src="profile.avatar || require('@/assets/pics/default_avatar.png')"
        />

        <div class="heading">
          {{ profile.firstName }} {{ profile.lastName }}
        </div>

        <!-- <div class="profile-section">
          <div>Location</div>
          <div>Space</div>
        </div> -->

        <div class="profile-section">
          <div>{{ $t('labels.phone') }}</div>
          <div>{{ profile.phoneNumber }}</div>
        </div>

        <div class="profile-section">
          <div>{{ $t('labels.email') }}</div>
          <div>{{ profile.email }}</div>
        </div>

        <div class="profile-section">
          <div>{{ $t('labels.workingTime') }}</div>
          <div>
            <div v-for="(value, key) in profile.workingHours" :key="key">
              <div style="display: flex">
                <div style="width: 40%">
                  {{ $t(`serviceProvider.settingsProfile.${key}`) }}
                </div>
                {{ value.hours.from }} -
                {{ value.hours.to }}
              </div>
            </div>
          </div>
        </div>

        <div class="profile-section">
          <div>{{ $t('labels.services') }}</div>
          <div>
            <span v-for="(service, i) in profile.subcategories" :key="i">
              {{ service.name
              }}<span v-if="i != Object.keys(profile.subcategories).length - 1"
                >,</span
              >
            </span>
          </div>
        </div>

        <!-- not needed for light version
          <div class="profile-section">
          <span @click="showPermissions">View Permissions</span>
        </div> -->
      </div>
    </div>
  </OutsideClickContainer>
</template>

<script>
import OutsideClickContainer from '../OutsideClickContainer/OutsideClickContainer';
import XIcon from '../Svgs/XIcon.vue';

export default {
  name: 'StaffSidePreview',

  props: {
    profile: {
      type: Object,
      default: () => {
        return {
          image: '',
          name: {
            first: 'George',
            last: 'Fields',
          },
          location: {
            label: 'Location',
            value: 'Berlin',
          },
          phone: {
            label: 'Phone',
            value: '+381 60 0 555 333',
          },
          email: {
            label: 'Email',
            value: 'george.fields@loremipsum.com',
          },
          working_time: {
            label: 'Working Time',
            value: '08:00-15:00',
          },
          services: {
            label: 'Services',
            value: ['Women’s Haircut', 'Men’s Haircut All Over Color'],
          },
        };
      },
    },
  },

  components: {
    OutsideClickContainer,
    XIcon,
  },

  data() {
    return {
      isActive: false,
    };
  },

  created() {
    setTimeout(() => {
      this.isActive = true;
    }, 200);
  },

  methods: {
    debounceClose() {
      this.isActive = false;
      setTimeout(() => {
        this.$emit('close');
      }, 350);
    },
    showPermissions() {
      this.$emit('permissions');
      this.isActive = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.staff-side-preview {
  position: fixed;
  right: -345px;
  top: 0;
  bottom: 0;
  z-index: 1001;
  width: 345px;
  height: 100%;
  background: #fff;
  transition: right 0.3s;

  &.active {
    right: 0;
  }
}
.content-wrap {
  padding: 50px;
}
.icon {
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
}
.image {
  width: 118px;
  height: 118px;
  background: grey;
  border-radius: 50%;
  margin-bottom: 1.5rem;
}
.heading {
  font-weight: bold;
  font-size: 20px;
  letter-spacing: 0.01em;
  color: #242f3c;
  margin-bottom: 32px;
}
.profile-section {
  margin-bottom: 24px;
  > div {
    &:nth-child(1) {
      padding-bottom: 5px;
      font-weight: 500;
      font-size: 14px;
      letter-spacing: 0.01em;
      color: #8497ab;
    }
    &:nth-child(2) {
      font-weight: 500;
      font-size: 16px;
      letter-spacing: 0.01em;
      color: #242f3c;
    }
  }
  > span {
    cursor: pointer;
    font-weight: 500;
    font-size: 14px;
    color: #e58413;
  }
}
</style>
