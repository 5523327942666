<template>
  <OutsideClickContainer @outsideClick="$emit('close')" active>
    <div class="modal-staff-edit px-3 py-4">
      <div class="header">
        <template v-if="edit">
          {{ $t('serviceProvider.settingsStaff.editStaff') }}
        </template>
        <template v-else>
          {{ $t('serviceProvider.settingsStaff.newStaff') }}
        </template>
        <div
          style="
            position: absolute;
            top: 0;
            right: 1rem;
            cursor: pointer;
            z-index: 1000;
          "
          @click="$emit('close')"
        >
          <XIcon />
        </div>
      </div>

      <div class="content row">
        <div class="info-content col-12 col-lg">
          <div class="row">
            <div class="input-wrap col">
              <label>{{ $t('serviceProvider.settingsStaff.firstName') }}</label>
              <InputElement
                v-model="firstName"
                validation-rules="required"
                validation-name="text"
                :placeholder="$t('serviceProvider.settingsStaff.firstName')"
              />
            </div>
            <div class="input-wrap col">
              <label>{{ $t('serviceProvider.settingsStaff.lastName') }}</label>
              <InputElement
                v-model="lastName"
                validation-rules="required"
                validation-name="text"
                :placeholder="$t('serviceProvider.settingsStaff.lastName')"
              />
            </div>
          </div>
          <!-- <div class="input-wrap">
            <label>{{ $t('serviceProvider.settingsStaff.location') }}</label>
            <InputElement
              v-model="location"
              validation-rules="required"
              validation-name="text"
              :placeholder="$t('serviceProvider.settingsStaff.location')"
            />
          </div> -->
          <div class="input-wrap">
            <label>{{ $t('serviceProvider.settingsStaff.phone') }}</label>
            <InputElement
              v-model="phone"
              validation-rules="required"
              validation-name="text"
              :placeholder="$t('serviceProvider.settingsStaff.phone')"
            />
          </div>
          <div class="input-wrap">
            <label>{{ $t('serviceProvider.settingsStaff.email') }}</label>
            <InputElement
              v-model="email"
              validation-rules="required"
              validation-name="text"
              :placeholder="$t('serviceProvider.settingsStaff.email')"
            />
          </div>
          <div class="input-wrap">
            <label>{{ $t('serviceProvider.settingsStaff.services') }}</label>
            <SelectElement
              v-model="services"
              :options="companyServices"
              :value="services"
              multi-select
              search
              validation-rules="required"
              validation-name="text"
            >
            </SelectElement>
          </div>
        </div>

        <div class="working-content col-auto">
          <label>{{ $t('serviceProvider.settingsStaff.workingTime') }}</label>
          <div class="working-content-wrap">
            <div
              v-for="(value, key) in workingHours"
              :key="key"
              class="work-day"
            >
              <div class="day-label" style="text-transform: capitalize">
                {{ $t(`serviceProvider.settingsStaff.${key}`) }}
              </div>
              <div class="day-time-box" style="margin-right: 5px">
                <!-- <span>{{ $t('serviceProvider.settingsStaff.startAt') }}:</span> -->
                <TimePicker
                  v-model="workingHours[key].hours.from"
                  :minute-interval="5"
                  close-on-complete
                  auto-scroll
                  placeholder="hh:mm"
                  class="working-hours-input"
                />
              </div>
              <span>&mdash;</span>
              <div class="day-time-box" style="margin-left: 5px">
                <!-- <span>{{ $t('serviceProvider.settingsStaff.endAt') }}:</span> -->
                <TimePicker
                  v-model="workingHours[key].hours.to"
                  :minute-interval="5"
                  :hour-range="timeEndRange(key).hour"
                  :minute-range="timeEndRange(key).minute"
                  hide-disabled-items
                  close-on-complete
                  auto-scroll
                  placeholder="hh:mm"
                  class="working-hours-input"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="breaktime-content col-auto">
          <label>{{ $t('serviceProvider.settingsStaff.breakTime') }}</label>
          <div class="working-content-wrap">
            <div
              v-for="(value, key) in workingHours"
              :key="key"
              class="work-day"
            >
              <div
                class="day-label d-md-none"
                style="text-transform: capitalize"
              >
                {{ $t(`serviceProvider.settingsStaff.${key}`) }}
              </div>
              <div class="day-time-box" style="margin-right: 5px">
                <!-- <span>{{ $t('serviceProvider.settingsStaff.startAt') }}:</span> -->
                <TimePicker
                  v-model="workingHours[key].break.from"
                  :minute-interval="5"
                  :hour-range="pauseTimeRange(key).hour"
                  :minute-range="pauseTimeRange(key).minute"
                  hide-disabled-items
                  close-on-complete
                  auto-scroll
                  placeholder="hh:mm"
                  class="working-hours-input"
                />
              </div>
              <span>&mdash;</span>
              <div class="day-time-box" style="margin-left: 5px">
                <!-- <span>{{ $t('serviceProvider.settingsStaff.endAt') }}:</span> -->
                <TimePicker
                  v-model="workingHours[key].break.to"
                  :minute-interval="5"
                  :hour-range="pauseTimeRange(key, true).hour"
                  :minute-range="pauseTimeRange(key, true).minute"
                  hide-disabled-items
                  close-on-complete
                  auto-scroll
                  placeholder="hh:mm"
                  class="working-hours-input"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <ResponseAlert
          v-if="$store.getters.errorResponse.action == 'staffEdit'"
          :response="$store.getters.errorResponse.data"
        ></ResponseAlert>
      </div>

      <div class="actions">
        <Button :orange-secondary="true" @click="$emit('close')">
          {{ $t('serviceProvider.settingsStaff.cancel') }}
        </Button>
        <template v-if="edit">
          <Button
            :orange="true"
            @click="update"
            :loading="$store.getters.loading"
          >
            {{ $t('serviceProvider.settingsStaff.save') }}
          </Button>
        </template>
        <template v-else>
          <Button
            :orange="true"
            @click="save"
            :loading="$store.getters.loading"
          >
            {{ $t('serviceProvider.settingsStaff.save') }}
          </Button>
        </template>
      </div>
      <SuccessNotification>
        <template v-if="!edit">
          Dein Mitarbeiter wurde erfolgreich hinzugefügt!
        </template>
        <template v-else>
          Die Mitarbeiter Daten wurden erfolgreich aktualisiert!
        </template>
      </SuccessNotification>
    </div>
  </OutsideClickContainer>
</template>

<script>
import store from '@/store';
import OutsideClickContainer from '@/components/OutsideClickContainer/OutsideClickContainer';
import InputElement from '@/components/FormControl/InputElement';
import SelectElement from '@/components/Staff/SelectElementCustom';
import Button from '@/components/FormControl/ButtonElement';
import SuccessNotification from '@/components/Staff/SuccessNotification';
import TimePicker from 'vue2-timepicker/src/vue-timepicker.vue';
import ResponseAlert from '@/components/Alerts/ResponseAlert';

const hoursEmptySet = () => ({
  hours: {
    from: '',
    to: '',
  },
  break: {
    from: '',
    to: '',
  },
});

export default {
  name: 'ModalStaffEdit',

  components: {
    OutsideClickContainer,
    InputElement,
    SelectElement,
    Button,
    SuccessNotification,
    TimePicker,
    ResponseAlert,
  },
  props: {
    profile: {
      type: [Object, Boolean],
    },
    edit: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      firstName: '',
      lastName: '',
      // location: '',
      phone: '',
      email: '',
      services: '',
      workingHours: {
        monday: hoursEmptySet(),
        tuesday: hoursEmptySet(),
        wednesday: hoursEmptySet(),
        thursday: hoursEmptySet(),
        friday: hoursEmptySet(),
        saturday: hoursEmptySet(),
        sunday: hoursEmptySet(),
      },
    };
  },
  created() {
    store.commit('setErrorResponse', {});
    store.dispatch('fetchCompanyCategories');
    if (this.edit) {
      this.populateData();
    }
  },
  methods: {
    save() {
      store.commit('setErrorResponse', {});
      store.dispatch('addStaffUser', this.formatData()).then(() => {
        this.$emit('close');
      });
    },
    update() {
      store.commit('setErrorResponse', {});
      store
        .dispatch('updateStaffUser', {
          id: this.profile.id,
          data: this.formatData(),
        })
        .then(() => {
          this.$emit('close');
        });
    },
    formatData() {
      return {
        first_name: this.firstName,
        last_name: this.lastName,
        phone_number: this.phone,
        email: this.email,
        subcategories: this.services ? this.services.join() : '',
        working_hours: JSON.stringify(this.workingHours),
      };
    },
    populateData() {
      this.firstName = this.profile.firstName;
      this.lastName = this.profile.lastName;
      this.phone = this.profile.phoneNumber;
      this.email = this.profile.email;
      this.services = this.parseServices(this.profile.subcategories);
      this.workingHours = this.profile.workingHours;

      for (const [day] of Object.entries(this.workingHours)) {
        if (this.workingHours[day]) {
          if (this.workingHours[day].hours.from == null)
            this.workingHours[day].hours.from = '';
          if (this.workingHours[day].hours.to == null)
            this.workingHours[day].hours.to = '';
          if (this.workingHours[day].break.from == null)
            this.workingHours[day].break.from = '';
          if (this.workingHours[day].break.to == null)
            this.workingHours[day].break.to = '';
        }
      }
    },
    parseServices(services) {
      let ob = [];
      services.forEach(el => {
        ob.push(el.id);
      });
      return ob;
    },
    resetFields() {
      this.firstName = '';
      this.lastName = '';
      this.location = '';
      this.phone = '';
      this.email = '';
      this.services = '';
      this.workingHours = {};
    },
    timeEndRange(day) {
      day = day.toLowerCase();
      if (!this.workingHours || !this.workingHours[day].hours.from) {
        if (this.firstName === '' && this.lastName === '') {
          let tmpWorkingHours = JSON.parse(store.getters.userInfo.workingHours);
          if (!tmpWorkingHours || !tmpWorkingHours[day].hours.from) {
            return {
              hour: [],
              minute: [],
            };
          } else {
            this.workingHours[day] = tmpWorkingHours[day];
          }
        } else {
          return {
            hour: [],
            minute: [],
          };
        }
      }

      const timeStart = this.workingHours[day].hours
        ? this.workingHours[day].hours.from.split(':')
        : '';
      const timeEnd = this.workingHours[day].hours
        ? this.workingHours[day].hours.to.split(':')
        : '';

      const hourRange = [];
      for (let i = 0; i < 24; ++i) {
        if (i < timeStart[0]) {
          continue;
        }

        hourRange.push(i);
      }

      const minuteRange = [];
      for (let i = 0; i < 60; i += 5) {
        if (timeEnd[0] == timeStart[0] && i <= timeStart[1]) {
          continue;
        }

        minuteRange.push(i);
      }

      return {
        hour: hourRange,
        minute: minuteRange,
      };
    },
    pauseTimeRange(day, isEndTime = false) {
      day = day.toLowerCase();
      if (
        !this.workingHours ||
        !this.workingHours[day].hours ||
        !this.workingHours[day].hours.from ||
        !this.workingHours[day].hours.to
      ) {
        if (this.firstName === '' && this.lastName === '') {
          let tmpWorkingHours = JSON.parse(store.getters.userInfo.workingHours);
          if (!tmpWorkingHours || !tmpWorkingHours[day].hours.from) {
            return {
              hour: [],
              minute: [],
            };
          } else {
            this.workingHours[day] = tmpWorkingHours[day];
          }
        } else {
          return {
            hour: [],
            minute: [],
          };
        }
      }

      const timeStart = this.workingHours[day].hours
        ? this.workingHours[day].hours.from.split(':')
        : '';
      const timeEnd = this.workingHours[day].hours
        ? this.workingHours[day].hours.to.split(':')
        : '';
      const breakTimeStart =
        this.workingHours &&
        this.workingHours[day] &&
        this.workingHours[day].break &&
        this.workingHours[day].break.from &&
        this.workingHours[day].break.from.length === 5
          ? this.workingHours[day].break.from.split(':')
          : '';
      const breakTimeEnd =
        this.workingHours[day] &&
        this.workingHours[day].break &&
        this.workingHours[day].break.to &&
        this.workingHours[day].break.to.length === 5
          ? this.workingHours[day].break.to.split(':')
          : '';

      const hourRange = [];
      for (let i = 0; i < 24; ++i) {
        if (i < timeStart[0] || i > timeEnd[0]) {
          continue;
        }

        if (isEndTime && i < breakTimeStart[0]) {
          continue;
        }

        hourRange.push(i);
      }

      const minuteRange = [];
      for (let i = 0; i < 60; i += 5) {
        if (!isEndTime) {
          if (breakTimeStart[0] == timeStart[0] && i < timeStart[1]) {
            continue;
          }

          if (breakTimeStart[0] == timeEnd[0] && i > timeEnd[1]) {
            continue;
          }
        } else {
          if (breakTimeEnd[0] == timeStart[0] && i < timeStart[1]) {
            continue;
          }

          if (breakTimeEnd[0] == timeEnd[0] && i > timeEnd[1]) {
            continue;
          }

          if (breakTimeEnd[0] == breakTimeStart[0] && i <= breakTimeStart[1]) {
            continue;
          }
        }

        minuteRange.push(i);
      }

      return {
        hour: hourRange,
        minute: minuteRange,
      };
    },
  },
  computed: {
    companyServices() {
      return this.$store.getters.companySubcategoriesOnly.map(item => ({
        label: item.name,
        value: item.id,
      }));
    },
  },
};
</script>

<style lang="scss">
.day-time-box .time-picker .dropdown {
  top: 32px !important;
}

.working-hours-input {
  width: 70px !important;

  .display-time {
    width: 70px !important;
    border: none !important;
    padding: 0 5px !important;
    height: unset !important;
  }
}
</style>

<style lang="scss" scoped>
.modal-staff-edit {
  background: #fdfdfd;
  box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
}
.header {
  position: relative;
  text-align: center;
  font-size: 24px;
  font-weight: 700 !important;
  color: #e58413;
  margin-bottom: 25px;
}
.work-day {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
.day-label {
  width: 100px;
  font-weight: 500;
  color: #2b2b2b;
}
.day-time-box {
  background: #fdfdfd;
  border: 1px solid #d4d4d4;
  border-radius: 10px;
  padding: 10px 12px;

  span:first-child {
    font-size: 1rem;
    font-weight: 500;
    color: #e58413;
  }
}
.input-reset {
  border: 0;
  outline: 0;
  font-size: 16px;
  width: 45px;
}
.actions {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;

  button {
    width: 33%;
    justify-content: center;
  }
}

.label,
label {
  display: block;
  font-weight: 500;
  color: #e58413;
  padding-bottom: 7px;
}
.input-wrap {
  margin-bottom: 24px;
}
</style>
