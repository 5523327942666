<template>
  <div>
    <div class="title">
      {{ $t('serviceProvider.settingsStaff.settingsSatff') }}
    </div>
    <Header
      :sorting-options="tableSorting.options"
      :search-label="$t('serviceProvider.settingsStaff.searchStaff')"
      @sort="option => (tableSorting.selected = option)"
      @search="inp => (search = inp)"
    >
      <template #button-1>
        <template v-if="!selected">
          <Button :orange="true" @click="modalActivate('newstaff')">
            <PlusIcon width="22px" height="22px" />
            {{ $t('serviceProvider.settingsStaff.newStaff') }}
          </Button>
        </template>
        <template v-else>
          <Button :orange="true" @click="editActivate">
            {{ $t('serviceProvider.settingsStaff.edit') }}
          </Button>
        </template>
      </template>

      <template #button-2>
        <!--
        Permissions not needed for light version
        <template v-if="!selectionActive">
          <Button :orange="true" @click="modalActivate('permissions')">
            Permissions
          </Button>
        </template>-->
        <template v-if="selected">
          <Button :orange-secondary="true" @click="deleteStaffUser">
            {{ $t('serviceProvider.settingsStaff.delete') }}
          </Button>
        </template>
      </template>
    </Header>
    <Table
      style="margin-bottom: 3rem"
      :data="staffUsers"
      :keys="tableKeys"
      :column-width="tableColumnWidth"
      @itemSelect="userPreviewActivate"
    >
      <template #cell-checkbox="{ value }">
        <div>
          <CheckboxElement
            :value="selected === value"
            @input="selectRow(value)"
          />
        </div>
      </template>

      <template #cell-name="{ value }">
        <span class="content">
          {{ value.firstName + ' ' + value.lastName }}
        </span>
      </template>

      <template #cell-workingHours="{ cell }">
        <div style="display: flex">
          <span>{{ cell && cell.monday ? cell.monday.hours.from : '' }}</span>
          <span style="padding-left: 3px; padding-right: 3px">-</span>
          <span>{{ cell && cell.monday ? cell.monday.hours.to : '' }}</span>
        </div>
      </template>
    </Table>
    <Modal :open="modalActive">
      <component
        :is="activeComponent"
        :profile="userPreview"
        :edit="editActive"
        @close="toggleModalClose"
        @permissions="showPermissions()"
      >
      </component>
    </Modal>
  </div>
</template>

<script>
import { sortBy, filter } from 'lodash';
import store from '@/store';
import Table from '@/components/Staff/TableDivCustom';
import Header from '@/components/Staff/TableHeaderCustom';
import Modal from '@/components/Staff/ModalCustom';
import ModalStaffEdit from '@/components/Staff/ModalStaffEdit';
import ModalStaffPermissions from '@/components/Staff/ModalStaffPermissions';
import StaffSidePreview from '@/components/Staff/StaffSidePreview';
import CheckboxElement from '@/components/Staff/CheckboxElementCustom';
import Button from '@/components/FormControl/ButtonElement';
import PlusIcon from '@/components/Svgs/PlusIcon';

export default {
  name: 'SettingsStaff',

  components: {
    Table,
    Header,
    Modal,
    ModalStaffEdit,
    ModalStaffPermissions,
    StaffSidePreview,
    CheckboxElement,
    PlusIcon,
    Button,
  },

  data() {
    return {
      modalActive: false,
      activeComponent: false,
      selected: false,
      editActive: false,
      userPreview: false,
      search: '',

      modalComponents: {
        newstaff: ModalStaffEdit,
        permissions: ModalStaffPermissions,
        sidepreview: StaffSidePreview,
      },
      tableSorting: {
        selected: false,
        options: [
          {
            label: this.$t('serviceProvider.settingsStaff.name'),
            value: 'firstName',
          },
          {
            label: this.$t('serviceProvider.settingsStaff.email'),
            value: 'email',
          },
          {
            label: this.$t('serviceProvider.settingsStaff.phone'),
            value: 'phoneNumber',
          },
        ],
      },
      tableKeys: [
        {
          key: 'checkbox',
          label: '',
          width: '50px',
        },
        {
          key: 'name',
          label: 'serviceProvider.settingsStaff.name',
          combined: ['firstName', 'lastName'],
          width: '100px',
        },
        {
          key: 'phoneNumber',
          label: 'serviceProvider.settingsStaff.phone',
          width: '100px',
        },
        {
          key: 'email',
          label: 'serviceProvider.settingsStaff.email',
          width: '100px',
        },
        {
          key: 'workingHours',
          label: 'serviceProvider.settingsStaff.workingTime',
          width: '100px',
        },
      ],

      tableColumnWidth: [
        { key: 'checkbox', width: '50px', fixed: true },
        { key: 'name', width: '150px', fixed: false },
        { key: 'phoneNumber', width: '150px', fixed: false },
        { key: 'email', width: '200px', fixed: false },
        { key: 'workingHours', width: '130px', fixed: false },
      ],
    };
  },
  mounted() {
    this.fetchStaff();
  },
  methods: {
    fetchStaff() {
      store.dispatch('fetchAllStaffUsers');
    },
    toggleModal() {
      this.modalActive = !this.modalActive;
    },
    toggleModalClose() {
      this.modalActive = !this.modalActive;
      if (!this.modalActive) {
        this.selected = false;
        this.editActive = false;
        this.userPreview = false;
      }
    },
    modalActivate(type) {
      this.activeComponent = this.modalComponents[type];
      this.toggleModal();
    },
    showPermissions() {
      setTimeout(() => {
        this.activeComponent = this.modalComponents['permissions'];
      }, 300);
    },
    selectRow(item) {
      this.selected = this.selected === item ? false : item;
      this.editActive = false;
    },
    userPreviewActivate(user) {
      this.userPreview = user;
      this.modalActivate('sidepreview');
    },
    editActivate() {
      this.editActive = true;
      this.userPreview = this.selected;
      this.modalActivate('newstaff');
    },
    deleteStaffUser() {
      if (this.selected) {
        store.dispatch('deleteStaffUser', this.selected);
        this.selected = '';
      }
    },
  },
  computed: {
    loading() {
      return store.getters.loading;
    },
    staffUsers() {
      if (this.search.length) {
        return filter(store.getters.staffUsersFormatted, el => {
          const fullName = el.firstName + ' ' + el.lastName;
          return fullName.toLowerCase().includes(this.search.toLowerCase());
        });
      }
      if (this.tableSorting.selected) {
        return sortBy(store.getters.staffUsersFormatted, el => {
          return el[this.tableSorting.selected].toLowerCase();
        });
      }
      return store.getters.staffUsersFormatted;
    },
  },
  watch: {
    staffUsers() {
      this.selected = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
