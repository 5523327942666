<template>
  <div class="modal-staff-permissions">
    <div class="header">
      Permissions
      <div
        style="
          position: absolute;
          top: 0;
          right: 0;
          cursor: pointer;
          z-index: 1000;
        "
        @click="$emit('close')"
      >
        <XIcon />
      </div>
    </div>

    <div class="content">
      <div class="content-top">
        <div class="content-section">
          <div class="section-subheader">Choose staff</div>
          <SelectElement
            v-model="choose_staff.selected"
            :options="choose_staff.options"
            multi-select
            search
            validation-rules="required"
            validation-name="text"
            placeholder="Choose staff"
          />
        </div>
      </div>
      <!-- Move to computed for -->
      <div class="main">
        <div class="content-left">
          <div class="subheader">View Permissions</div>

          <div class="content-section">
            <div class="section-subheader">Dashboard</div>
            <div
              v-for="(item, i) in view_permissions.dashboard"
              :key="i"
              class="section-item"
            >
              <div class="section-item-checkbox">
                <CheckboxElement
                  :value="item.selected"
                  @input="() => (item.selected = !item.selected)"
                />
              </div>
              <div class="section-item-label">
                {{ item.label }}
              </div>
            </div>
          </div>
          <div class="content-section">
            <div class="section-subheader">Calendar</div>
            <div
              v-for="(item, i) in view_permissions.calendar"
              :key="i"
              class="section-item"
            >
              <div class="section-item-checkbox">
                <CheckboxElement
                  :value="item.selected"
                  @input="() => (item.selected = !item.selected)"
                />
              </div>
              <div class="section-item-label">
                {{ item.label }}
              </div>
            </div>
          </div>
          <div class="content-section">
            <div class="section-subheader">Clients</div>
            <div
              v-for="(item, i) in view_permissions.clients"
              :key="i"
              class="section-item"
            >
              <div class="section-item-checkbox">
                <CheckboxElement
                  :value="item.selected"
                  @input="() => (item.selected = !item.selected)"
                />
              </div>
              <div class="section-item-label">
                {{ item.label }}
              </div>
            </div>
          </div>
          <div class="content-section">
            <div class="section-subheader">Reports</div>
            <div
              v-for="(item, i) in view_permissions.reports"
              :key="i"
              class="section-item"
            >
              <div class="section-item-checkbox">
                <CheckboxElement
                  :value="item.selected"
                  @input="() => (item.selected = !item.selected)"
                />
              </div>
              <div class="section-item-label">
                {{ item.label }}
              </div>
            </div>
          </div>
          <div class="content-section">
            <div class="section-subheader">Company Information</div>
            <div
              v-for="(item, i) in view_permissions.company_information"
              :key="i"
              class="section-item"
            >
              <div class="section-item-checkbox">
                <CheckboxElement
                  :value="item.selected"
                  @input="() => (item.selected = !item.selected)"
                />
              </div>
              <div class="section-item-label">
                {{ item.label }}
              </div>
            </div>
          </div>
          <div class="content-section">
            <div class="section-subheader">Services</div>
            <div
              v-for="(item, i) in view_permissions.services"
              :key="i"
              class="section-item"
            >
              <div class="section-item-checkbox">
                <CheckboxElement
                  :value="item.selected"
                  @input="() => (item.selected = !item.selected)"
                />
              </div>
              <div class="section-item-label">
                {{ item.label }}
              </div>
            </div>
          </div>
        </div>

        <div class="content-right">
          <div class="content-section">
            <div class="section-subheader">Products</div>
            <div
              v-for="(item, i) in view_permissions.products"
              :key="i"
              class="section-item"
            >
              <div class="section-item-checkbox">
                <CheckboxElement
                  :value="item.selected"
                  @input="() => (item.selected = !item.selected)"
                />
              </div>
              <div class="section-item-label">
                {{ item.label }}
              </div>
            </div>
          </div>
          <div class="content-section">
            <div class="section-subheader">Ads</div>
            <div
              v-for="(item, i) in view_permissions.ads"
              :key="i"
              class="section-item"
            >
              <div class="section-item-checkbox">
                <CheckboxElement
                  :value="item.selected"
                  @input="() => (item.selected = !item.selected)"
                />
              </div>
              <div class="section-item-label">
                {{ item.label }}
              </div>
            </div>
          </div>
          <div class="content-section">
            <div class="section-subheader">Social Projects</div>
            <div
              v-for="(item, i) in view_permissions.social_projects"
              :key="i"
              class="section-item"
            >
              <div class="section-item-checkbox">
                <CheckboxElement
                  :value="item.selected"
                  @input="() => (item.selected = !item.selected)"
                />
              </div>
              <div class="section-item-label">
                {{ item.label }}
              </div>
            </div>
          </div>

          <div class="subheader" style="padding-top: 20px">
            Edit Permissions
          </div>

          <div class="content-section">
            <div class="section-subheader">Calendar</div>
            <div
              v-for="(item, i) in edit_permissions.calendar"
              :key="i"
              class="section-item"
            >
              <div class="section-item-checkbox">
                <CheckboxElement
                  :value="item.selected"
                  @input="() => (item.selected = !item.selected)"
                />
              </div>
              <div class="section-item-label">
                {{ item.label }}
              </div>
            </div>
          </div>
          <div class="content-section">
            <div class="section-subheader">Clients</div>
            <div
              v-for="(item, i) in edit_permissions.clients"
              :key="i"
              class="section-item"
            >
              <div class="section-item-checkbox">
                <CheckboxElement
                  :value="item.selected"
                  @input="() => (item.selected = !item.selected)"
                />
              </div>
              <div class="section-item-label">
                {{ item.label }}
              </div>
            </div>
          </div>
          <div class="content-section">
            <div class="section-subheader">My Profile</div>
            <div
              v-for="(item, i) in edit_permissions.my_profile"
              :key="i"
              class="section-item"
            >
              <div class="section-item-checkbox">
                <CheckboxElement
                  :value="item.selected"
                  @input="() => (item.selected = !item.selected)"
                />
              </div>
              <div class="section-item-label">
                {{ item.label }}
              </div>
            </div>
          </div>
          <div class="content-section">
            <div class="section-subheader">Services/Products</div>
            <div
              v-for="(item, i) in edit_permissions.services_products"
              :key="i"
              class="section-item"
            >
              <div class="section-item-checkbox">
                <CheckboxElement
                  :value="item.selected"
                  @input="() => (item.selected = !item.selected)"
                />
              </div>
              <div class="section-item-label">
                {{ item.label }}
              </div>
            </div>
          </div>
          <div class="content-section">
            <div class="section-subheader">Ads</div>
            <div
              v-for="(item, i) in edit_permissions.ads"
              :key="i"
              class="section-item"
            >
              <div class="section-item-checkbox">
                <CheckboxElement
                  :value="item.selected"
                  @input="() => (item.selected = !item.selected)"
                />
              </div>
              <div class="section-item-label">
                {{ item.label }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="actions">
      <Button :orangeSecondary="true" @click="$emit('close')"> Cancel </Button>
      <Button :orange="true"> Save </Button>
    </div>
  </div>
</template>

<script>
import CheckboxElement from '@/components/FormControl/CheckboxElement';
import DropdownSelect from '@/components/FormControl/SelectElement/SelectElement';
import SelectElement from '@/components/FormControl/SelectElement/SelectElement';
import Button from '@/components/FormControl/ButtonElement';
import XIcon from '../Svgs/XIcon.vue';

export default {
  name: 'ModalStaffPermissions',

  components: {
    CheckboxElement,
    DropdownSelect,
    SelectElement,
    Button,
    XIcon,
  },

  data() {
    return {
      choose_staff: {
        selected: '',
        options: [
          {
            label: 'One',
            value: 'one',
          },
          {
            label: 'Two',
            value: 'two',
          },
          {
            label: 'Drei',
            value: 'drei',
          },
        ],
      },
      view_permissions: {
        dashboard: [
          {
            label: 'Allow this staff to view own BookAppointment graph',
            selected: false,
          },
          {
            label: 'Allow this staff to view company BookAppointment graph',
            selected: false,
          },
          {
            label: 'Allow this staff to view own appointments',
            selected: false,
          },
          {
            label: 'Allow this staff to view company appointments',
            selected: false,
          },
        ],
        calendar: [
          {
            label: 'View own appointments',
            selected: false,
          },
          {
            label: 'View company appointments',
            selected: false,
          },
        ],
        clients: [
          {
            label: 'View own Clients',
            selected: false,
          },
          {
            label: 'View company Clients',
            selected: false,
          },
        ],
        reports: [
          {
            label: 'View company Reports',
            selected: false,
          },
        ],
        company_information: [
          {
            label: 'View company information',
            selected: false,
          },
        ],
        services: [
          {
            label: 'View Services',
            selected: false,
          },
        ],
        products: [
          {
            label: 'View Products',
            selected: false,
          },
        ],
        ads: [
          {
            label: 'View Advertisements',
            selected: false,
          },
        ],
        social_projects: [
          {
            label: 'View Social Projects',
            selected: false,
          },
        ],
      },
      edit_permissions: {
        calendar: [
          {
            label: 'Edit own appointments',
            selected: false,
          },
          {
            label: 'Edit company appointments',
            selected: false,
          },
        ],
        clients: [
          {
            label: 'Create/edit Clients',
            selected: false,
          },
        ],
        my_profile: [
          {
            label: 'Edit own profile information',
            selected: false,
          },
        ],
        services_products: [
          {
            label: 'Create/edit Services and Products',
            selected: false,
          },
        ],
        ads: [
          {
            label: 'Create/edit Advertisements',
            selected: false,
          },
        ],
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.modal-staff-permissions {
  background: #fdfdfd;
  box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  padding: 30px 40px 30px 40px;
}
.header {
  position: relative;
  text-align: center;
  font-size: 24px;
  font-weight: 700 !important;
  color: #e58413;
  margin-bottom: 25px;
}
.main {
  display: flex;
}
.content-top {
  width: 50%;
  padding-bottom: 28px;
}
.content-left {
  padding-right: 150px;
}
.subheader {
  font-weight: bold;
  font-size: 20px;
  color: #e58413;
  padding-bottom: 5px;
}
.section-subheader {
  font-weight: 500;
  font-size: 20px;
  color: #e58413;
  padding-bottom: 20px;
  padding-top: 18px;
}
.section-item {
  display: flex;
  padding-bottom: 16px;
}
.section-item-checkbox {
  margin-right: 16px;
}

.actions {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;

  button {
    width: 33%;
    justify-content: center;
  }
}
</style>
