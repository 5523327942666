<template>
  <div class="services-retail-event">
    <div class="input-section">
      <label>{{ event_name.label }}</label>
      <InputElement
        v-model="event_name.value"
        validation-rules="required"
        validation-name="text"
        :placeholder="event_name.label"
      />
    </div>

    <div class="input-section">
      <label>{{ products.label }}</label>
      <InputElement
        v-model="products.value"
        validation-rules="required"
        validation-name="text"
        :placeholder="products.label"
      />
    </div>

    <div class="input-section">
      <label>{{ location.label }}</label>
      <InputElement
        v-model="location.value"
        validation-rules="required"
        validation-name="text"
        :placeholder="location.label"
      />
    </div>

    <div class="double-section">
      <div class="input-section">
        <label>{{ start_time.label }}</label>
        <InputElement
          v-model="start_time.value"
          validation-rules="required"
          validation-name="text"
          :placeholder="start_time.label"
        />
      </div>
      <div class="input-section">
        <label>{{ end_time.label }}</label>
        <InputElement
          v-model="end_time.value"
          validation-rules="required"
          validation-name="text"
          :placeholder="end_time.label"
        />
      </div>
    </div>

    <div class="input-section">
      <label>{{ description.label }}</label>
      <Textarea
        v-model="description.value"
        validation-rules="required"
        validation-name="text"
        :placeholder="description.label"
        style="height: 140px"
      />
    </div>

    <div
      class="input-section"
      style="display: flex; justify-content: space-between"
    >
      <label>{{ recurring_service.label }}</label>
      <CheckboxElement
        :value="recurring_service.value"
        @input="() => (recurring_service.value = !recurring_service.value)"
      />
    </div>

    <div class="input-section">
      <label>Select price type</label>
      <div style="display: flex; justify-content: space-between">
        <div
          v-for="(price, i) in price_type.options"
          :key="i"
          style="display: flex"
        >
          <RadioElement
            @input="aa => (price_type.selected = aa)"
            id="price"
            :radioValue="price.value"
            :name="price.label"
            :value="price_type.selected"
          >
          </RadioElement>
          <span class="radio-label" style="padding-left: 10px">
            {{ price.label }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InputElement from '@/components/FormControl/InputElement';
import DropdownSelect from '@/components/FormControl/SelectElement/SelectElement';
import Textarea from '@/components/FormControl/TextareaElement';
import RadioElement from '@/components/FormControl/RadioElement';
import CheckboxElement from '@/components/FormControl/CheckboxElement';

export default {
  name: 'ServicesRetailEvent',

  components: {
    InputElement,
    DropdownSelect,
    Textarea,
    RadioElement,
    CheckboxElement,
  },

  data() {
    return {
      event_name: {
        label: 'Events name',
        value: '',
      },
      products: {
        label: 'Products',
        value: '',
      },
      location: {
        label: 'Event location',
        value: '',
      },
      start_time: {
        label: 'Start time',
        value: '',
      },
      end_time: {
        label: 'End time',
        value: '',
      },
      description: {
        label: 'Description',
        value: '',
      },
      recurring_service: {
        label: 'Recurring service',
        value: true,
      },
      price_type: {
        label: 'Select price type',
        selected: '',
        options: [
          {
            id: 1,
            label: 'Fixed price',
            value: 'Fixed price',
          },
          {
            id: 2,
            label: 'Price up to',
            value: 'Price up to',
          },
          {
            id: 3,
            label: 'No price',
            value: 'No price',
          },
        ],
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.input-section {
  margin-bottom: 22px;

  label {
    display: block;
    margin-bottom: 12px;
    font-weight: 500;
    font-size: 16px;
    color: #e58413;
  }
}

.double-section {
  display: flex;
  justify-content: space-between;
}
</style>
