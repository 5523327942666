var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$t('serviceProvider.settingsServices.settingsServices'))+" ")]),_c('Header',{attrs:{"sorting-options":_vm.tableSorting.options,"search-label":_vm.$t('serviceProvider.settingsServices.searchServices')},on:{"sort":function (option) { return (_vm.tableSorting.selected = option); },"search":function (inp) { return (_vm.search = inp); }},scopedSlots:_vm._u([{key:"button-1",fn:function(){return [(!_vm.selected)?[_c('Button',{attrs:{"orange":true},on:{"click":_vm.proceedToCreate}},[_c('PlusIcon',{attrs:{"width":"22px","height":"22px"}}),_vm._v(" "+_vm._s(_vm.$t('serviceProvider.settingsServices.createService'))+" ")],1)]:[_c('Button',{attrs:{"orange":true},on:{"click":_vm.serviceEdit}},[_vm._v(" "+_vm._s(_vm.$t('serviceProvider.settingsServices.edit'))+" ")])]]},proxy:true},{key:"button-2",fn:function(){return [(!_vm.selected)?[_c('Button',{attrs:{"orange-secondary":true},on:{"click":function($event){return _vm.modalActivate('category')}}},[_c('PlusIcon',{attrs:{"width":"22px","height":"22px","color":"#e58413"}}),_vm._v(" "+_vm._s(_vm.$t('serviceProvider.settingsServices.createCategory'))+" ")],1)]:[_c('Button',{attrs:{"orange-secondary":true},on:{"click":_vm.serviceDelete}},[_vm._v(" "+_vm._s(_vm.$t('serviceProvider.settingsServices.delete'))+" ")])]]},proxy:true}])}),_c('Table',{staticStyle:{"margin-bottom":"1.5rem"},attrs:{"data":_vm.services,"keys":_vm.tableKeys,"column-width":_vm.tableColumnWidth,"selectable":true},on:{"itemSelect":_vm.selectItem},scopedSlots:_vm._u([{key:"cell-images",fn:function(ref){
var value = ref.value;
return [_c('div',[_c('img',{staticClass:"image",attrs:{"src":value.images[0] || require('@/assets/pics/logo_black.png')}})])]}},{key:"cell-category",fn:function(ref){
var cell = ref.cell;
return [_c('div',[_vm._v(" "+_vm._s(cell ? cell.name : '')+" ")])]}},{key:"cell-duration",fn:function(ref){
var value = ref.value;
return [_c('span',[_vm._v(_vm._s(value.duration)+" Minuten")])]}},{key:"cell-price",fn:function(ref){
var value = ref.value;
return [_c('span',[_vm._v(_vm._s(_vm._f("EUR")(value.price)))])]}}])}),_c('Modal',{attrs:{"open":_vm.modalActive}},[_c(_vm.active,{tag:"component",on:{"close":_vm.toggleModal}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }