<template>
  <div class="settings-services-add">
    <div class="header row">
      <div class="title col-12 col-md-6">
        {{ $t('serviceProvider.settingsServices.services') }}
        ->
        {{ $t('serviceProvider.settingsServices.createService') }}
      </div>
      <div class="tabs col-12 col-md-6 my-4 my-md-0 d-flex align-items-center">
        <span
          :class="['tab', { active: selectedTab === tab.name }]"
          v-for="(tab, i) in tabs"
          :key="i"
          @click="selectTab(tab.name)"
        >
          {{ tab.label }}
        </span>
      </div>
    </div>

    <div class="content row">
      <div class="left col-12 col-md-6 mb-5 mb-md-0">
        <keep-alive>
          <Gallery @images="setImages" :edit="edit" />
        </keep-alive>
      </div>
      <div class="right col-12 col-md-6">
        <component
          :is="activeTab.component"
          @updateForm="updateForm"
          :edit="edit"
        >
        </component>
      </div>
    </div>

    <div>
      <ResponseAlert
        v-if="$store.getters.errorResponse.action == 'serviceEdit'"
        :response="$store.getters.errorResponse.data"
        class="mt-3"
      ></ResponseAlert>
    </div>

    <div class="actions" style="margin-bottom: 4rem">
      <Button :orange-secondary="true" @click="cancel()">
        {{ $t('serviceProvider.settingsServices.cancel') }}
      </Button>
      <template v-if="!edit">
        <Button
          :orange="true"
          :loading="$store.getters.loading"
          @click="addService"
        >
          {{ $t('serviceProvider.settingsServices.save') }}
        </Button>
      </template>
      <template v-else>
        <Button :orange="true" @click="updateService">
          {{ $t('serviceProvider.settingsServices.save') }}
        </Button>
      </template>
    </div>

    <SuccessNotification>
      Deine Dienstleistung wurde erfolgreich erstellt!
    </SuccessNotification>
  </div>
</template>

<script>
import store from '@/store';
import Gallery from '@/components/Services/ServicesGallery';
import Service from '@/components/Services/ServicesService';
import Class from '@/components/Services/ServicesClass';
import RetailEvent from '@/components/Services/ServicesRetailEvent';
import Button from '@/components/FormControl/ButtonElement';
import SuccessNotification from '@/components/Staff/SuccessNotification';
import ResponseAlert from '@/components/Alerts/ResponseAlert';

export default {
  name: 'SettingsServicesAdd',

  components: {
    Gallery,
    Service,
    Class,
    RetailEvent,
    Button,
    SuccessNotification,
    ResponseAlert,
  },

  props: {
    type: [String, Number],
  },

  data() {
    return {
      tabs: {
        service: {
          label: 'Dienstleistungen',
          name: 'service',
          component: Service,
          form: {},
          images: [],
        },
        // class: {
        //   label: 'Class',
        //   name: 'class',
        //   component: Class,
        //   form: {},
        // },
        // retail_event: {
        //   label: 'Retail Event',
        //   name: 'retail_event',
        //   component: RetailEvent,
        //   form: {},
        // },
      },
      selectedTab: 'service',
      edit: false,
    };
  },

  created() {
    store.commit('setErrorResponse', {});

    if (this.$route.params.service) {
      this.populateData(this.$route.params.service);
    }
  },

  computed: {
    activeTab() {
      return this.tabs[this.selectedTab];
    },
  },
  methods: {
    selectTab(type) {
      this.selectedTab = type;
    },
    updateForm(data, type) {
      this.tabs[type].form = data;
    },
    setImages(images) {
      this.tabs.service.images.push(images);
      // this.tabs.service.form.images = images;
    },
    populateData(data) {
      this.edit = data;
      // this.tabs.service.form = data;
    },
    cancel() {
      this.$router.push({ name: 'SettingsServices' });
    },
    addService() {
      store.commit('setErrorResponse', {});
      // this.tabs.service.form.images = this.tabs.service.images;

      store.dispatch('addService', this.getFormData()).then(() => {
        this.$router.push('/settings/services');
      });
    },
    updateService() {
      store.commit('setErrorResponse', {});
      // this.tabs.service.form.images = this.tabs.service.images;

      store.dispatch('updateService', {
        id: this.edit.id,
        data: this.getFormData(),
      });
    },
    getFormData() {
      let data = new FormData();
      this.tabs.service.images.forEach(img => {
        data.append('images[]', img);
      });

      data.append('category_id', this.tabs.service.form.category_id);
      data.append('description', this.tabs.service.form.description);
      data.append('price', this.tabs.service.form.price);
      data.append('duration', this.tabs.service.form.duration);
      data.append('price_type', this.tabs.service.form.price_type);
      data.append('name', this.tabs.service.form.name);
      data.append('staff', this.tabs.service.form.staff);
      return data;
    },
  },
};
</script>

<style lang="scss" scoped>
.settings-services-add {
}
.header {
  .title {
    font-weight: bold;
    font-size: 24px;
    color: #e58413;
    margin-bottom: 0;
    padding-right: 100px;
  }
}

.tabs {
  .tab {
    font-weight: 500;
    font-size: 20px;
    color: #999999;
    padding-right: 30px;
    cursor: pointer;

    &.active {
      font-weight: bold;
      font-size: 24px;
      color: #e58413;
    }
  }
}

.actions {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;

  button {
    width: 33%;
    justify-content: center;
  }
}
</style>
