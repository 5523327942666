<template>
  <div class="modal-services-category">
    <div class="header">
      {{ $t('serviceProvider.settingsServices.createCategory') }}
      <div
        style="
          position: absolute;
          top: 0;
          right: 0;
          cursor: pointer;
          z-index: 1000;
        "
        @click="$emit('close')"
      >
        <XIcon />
      </div>
    </div>
    <div class="content">
      <div class="input-wrap">
        <label class="label">
          {{ $t('serviceProvider.settingsServices.subcategory') }}
        </label>
        <SelectElement
          v-model="formData.subcategory_id"
          :options="subcategoryOptions"
          search
          validation-rules="required"
          :placeholder="$t('serviceProvider.settingsServices.subcategory')"
        />
      </div>

      <div class="input-wrap">
        <label>
          {{ $t('serviceProvider.settingsServices.cateogoryName') }}
        </label>
        <InputElement
          v-model="formData.name"
          validation-rules="required"
          validation-name="text"
          :placeholder="$t('serviceProvider.settingsServices.cateogoryName')"
        />
      </div>

      <div class="input-wrap">
        <label>Bestehende Kategorien:</label>
        <div style="max-height: 100px; overflow-y: auto">
          <li
            v-for="cat in $store.getters.companyServiceCategories"
            :key="cat.id"
          >
            {{ cat.serviceCategoryName }}
          </li>
        </div>
      </div>
      <!--<div class="input-wrap">
        <label>
          {{ $t('serviceProvider.settingsServices.chooseServices') }}
        </label>
        <SelectElement
          v-model="service.selected"
          :options="service.options"
          multi-select
          search
          validation-rules="required"
          validation-name="text"
          placeholder="Services"
        />
      </div>-->
    </div>

    <div>
      <ResponseAlert
        v-if="$store.getters.errorResponse.action == 'createServiceCategory'"
        :response="$store.getters.errorResponse.data"
      ></ResponseAlert>
    </div>

    <div class="actions">
      <Button :orange-secondary="true" @click="$emit('close')">
        {{ $t('serviceProvider.settingsServices.cancel') }}
      </Button>
      <Button :orange="true" @click="save" :loading="$store.getters.loading">
        {{ $t('serviceProvider.settingsServices.save') }}
      </Button>
    </div>

    <SuccessNotification>
      Deine Kategorie wurde erfolgreich erstellt!
    </SuccessNotification>
  </div>
</template>

<script>
import InputElement from '@/components/FormControl/InputElement';
import SelectElement from '@/components/FormControl/SelectElement/SelectElement';
import Button from '@/components/FormControl/ButtonElement';
import XIcon from '../Svgs/XIcon.vue';
import SuccessNotification from '@/components/Staff/SuccessNotification';
import ResponseAlert from '@/components/Alerts/ResponseAlert';

export default {
  name: 'ModalServicesCategory',

  components: {
    InputElement,
    SelectElement,
    Button,
    XIcon,
    SuccessNotification,
    ResponseAlert,
  },

  data() {
    return {
      formData: {
        name: '',
        subcategory_id: '',
      },
    };
  },
  computed: {
    subcategoryOptions() {
      return this.$store.getters.companySubcategoriesOnly.map(item => ({
        label: item.name,
        value: item.id,
      }));
    },
  },
  created() {
    this.$store.commit('setErrorResponse', {});
    this.$store.dispatch('fetchCompanyCategories');
    this.$store.dispatch('fetchCompanyServiceCategories');
  },
  methods: {
    save() {
      this.$store
        .dispatch('createServiceCategory', {
          name: this.formData.name,
          subcategory_id: this.formData.subcategory_id,
        })
        .then(() => {
          this.$emit('close');
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-services-category {
  max-width: 500px;
  background: #fdfdfd;
  box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  padding: 30px 40px 30px 40px;
}
.header {
  position: relative;
  text-align: center;
  font-size: 24px;
  font-weight: 700 !important;
  color: #e58413;
  margin-bottom: 25px;
}
.input-wrap {
  margin-bottom: 24px;

  label {
    display: inline-block;
    font-weight: 500;
    color: #e58413;
    padding-bottom: 7px;
  }
}
.actions {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;

  button {
    width: 48%;
    justify-content: center;
  }
}
</style>
