<template>
  <div class="company-info">
    <div class="title">
      {{ $t(`enduser.settings.personalInformation`) }}
    </div>

    <ValidationObserver ref="updateInfoForm" v-slot="{ invalid }">
      <div class="form-control-custom-custom-group">
        <div class="form-control-custom half-w">
          <label class="label">{{ $t(`enduser.logIn.firstName`) }}</label>
          <InputElement
            v-model="userInfoPayload.firstName"
            :placeholder="$t(`enduser.logIn.firstName`)"
            validation-rules="required"
          />
        </div>
        <div class="form-control-custom half-w">
          <label class="label">{{ $t(`enduser.logIn.lastName`) }}</label>
          <InputElement
            v-model="userInfoPayload.lastName"
            :placeholder="$t(`enduser.logIn.lastName`)"
            validation-rules="required"
          />
        </div>
      </div>

      <div class="form-control-custom-custom-group">
        <div class="form-control-custom full-w">
          <label class="label">{{ $t(`enduser.settings.email`) }}</label>
          <InputElement
            v-model="userInfoPayload.email"
            :placeholder="$t(`enduser.settings.email`)"
            type="email"
            validation-rules="required|email"
          />
        </div>
      </div>

      <div class="form-control-custom-custom-group">
        <div class="form-control-custom half-w">
          <label class="label">{{ $t(`enduser.settings.city`) }}</label>
          <GooglePlacesAutocomplete
            :value="userInfoPayload.city"
            search-type="(cities)"
            @select="selectGooglePlace('city', $event)"
            :placeholder="$t(`enduser.settings.city`)"
          >
            <template #list-label="{ value }">
              {{ value.description }}
            </template>
          </GooglePlacesAutocomplete>
        </div>
        <div class="form-control-custom half-w">
          <label class="label">{{ $t(`enduser.settings.address`) }}</label>
          <GooglePlacesAutocomplete
            :value="userInfoPayload.address"
            search-type="address"
            @select="selectGooglePlace('address', $event)"
            :placeholder="$t(`enduser.settings.address`)"
          >
            <template #list-label="{ value }">
              {{ value.description }}
            </template>
          </GooglePlacesAutocomplete>
        </div>
      </div>

      <div class="form-control-custom-custom-group">
        <div class="form-control-custom full-w">
          <label class="label">
            {{ $t(`enduser.settings.changePassword`) }}
          </label>
          <InputElement
            v-model="userInfoPayload.password"
            type="password"
            :placeholder="$t(`enduser.settings.currentPasswort`)"
            validation-rules="min:8"
          />
        </div>
      </div>

      <div class="form-control-custom-custom-group">
        <div class="form-control-custom half-w">
          <InputElement
            v-model="userInfoPayload.newPassword"
            type="password"
            :placeholder="$t(`enduser.logIn.password`)"
            validation-rules="min:8"
            validation-name="password"
          />
        </div>
        <div class="form-control-custom half-w">
          <InputElement
            v-model="userInfoPayload.confirmPassword"
            type="password"
            :placeholder="$t(`enduser.logIn.confirmPassword`)"
            validation-rules="password:@password"
            validation-name="confirmPassword"
          />
        </div>
      </div>

      <div class="form-control-custom-custom-group">
        <div class="form-control-custom full-w">
          <label class="label">{{ $t(`enduser.logIn.phoneNumber`) }}</label>
          <InputElement
            v-model="userInfoPayload.phoneNumber"
            :placeholder="$t(`enduser.logIn.phoneNumber`)"
            validation-rules="required"
          />
        </div>
      </div>

      <ResponseAlert
        v-if="$store.getters.errorResponse.action == 'updateUserInfo'"
        :response="$store.getters.errorResponse.data"
        class="mt-3"
      ></ResponseAlert>

      <div class="form-control-custom-custom-group mt-4">
        <div class="form-control-custom half-w">
          <ButtonElement block orange-secondary>
            {{ $t(`retailer.settingsCompany.cancel`) }}
          </ButtonElement>
        </div>
        <div class="form-control-custom half-w">
          <ButtonElement
            block
            orange
            @click="handleSave"
            :disabled="invalid"
            :loading="$store.getters.loading"
          >
            {{ $t(`retailer.settingsCompany.save`) }}
          </ButtonElement>
        </div>
      </div>
    </ValidationObserver>
  </div>
</template>

<script>
import { ref, reactive } from '@vue/composition-api';
import ButtonElement from '../FormControl/ButtonElement.vue';
import InputElement from '../FormControl/InputElement.vue';
import SelectElement from '../FormControl/SelectElement/SelectElement.vue';
import GooglePlacesAutocomplete from '@/components/FormControl/SelectElement/GooglePlacesAutocomplete.vue';
import store from '../../store';

export default {
  setup() {
    const updateInfoForm = ref(null);
    const userInfoPayload = reactive({
      firstName: store.getters.userInfo.firstName,
      lastName: store.getters.userInfo.lastName,
      email: store.getters.userInfo.email,
      city: store.getters.userInfo.city ? store.getters.userInfo.city : '',
      address: store.getters.userInfo.address
        ? store.getters.userInfo.address
        : '',
      phoneNumber: store.getters.userInfo.phoneNumber,
      password: '',
      newPassword: '',
      confirmPassword: '',
    });

    const selectGooglePlace = (type, value) => {
      userInfoPayload[type] = value.structured_formatting.main_text;
    };

    const handleSave = async () => {
      let payload = {};
      if (userInfoPayload.firstName !== store.getters.userInfo.firstName)
        payload['firstName'] = userInfoPayload.firstName;
      if (userInfoPayload.lastName !== store.getters.userInfo.lastName)
        payload['lastName'] = userInfoPayload.lastName;
      if (userInfoPayload.email !== store.getters.userInfo.email)
        payload['email'] = userInfoPayload.email;
      if (userInfoPayload.city !== store.getters.userInfo.city)
        payload['city'] = userInfoPayload.city;
      if (userInfoPayload.address !== store.getters.userInfo.address)
        payload['address'] = userInfoPayload.address;
      if (userInfoPayload.address !== store.getters.userInfo.address)
        payload['address'] = userInfoPayload.address;
      if (userInfoPayload.phoneNumber !== store.getters.userInfo.phoneNumber)
        payload['phoneNumber'] = userInfoPayload.phoneNumber;
      if (userInfoPayload.password.length) {
        payload['password'] = userInfoPayload.password;
        payload['newPassword'] = userInfoPayload.newPassword;
        payload['confirmPassword'] = userInfoPayload.confirmPassword;
      }

      const valid = await updateInfoForm.value.validate();
      if (!valid) return;
      await store.dispatch('updateUserInfo', payload);
    };

    return {
      updateInfoForm,
      userInfoPayload,
      handleSave,
      selectGooglePlace,
    };
  },
  components: {
    ButtonElement,
    InputElement,
    SelectElement,
    GooglePlacesAutocomplete,
  },
};
</script>

<style lang="scss" scoped>
.title {
  text-align: center;
  padding-top: 0.5rem;
}

.space-top {
  margin-top: 3rem;
}
</style>
