<template>
  <div class="company">
    <div class="title" style="text-align: left">
      {{ $t(`enduser.settings.account`) }}
    </div>

    <header class="row py-3 px-1 mx-0 mb-4">
      <div class="col-auto">
        <CompanyProfilePhoto />
      </div>
      <div class="col d-flex align-items-end">
        <div style="width: 100%">
          <div class="company-name">
            {{ $store.getters.userInfo.firstName }}
            {{ $store.getters.userInfo.lastName }}
          </div>
          <div class="logout d-flex justify-content-end mt-3 mt-sm-0">
            <ButtonElement @click="handleLogout" orange>{{
              $t(`retailer.settingsCompany.logOut`)
            }}</ButtonElement>
          </div>
        </div>
      </div>
    </header>

    <div class="company-details row mt-4">
      <UserInfo class="company-details-box col-12 col-xl-6" />
      <div class="company-details-box col-12 col-xl-6 mt-4 mt-xl-0 mb-5">
        <div class="grey-box">
          <div class="title">
            {{ $t(`retailer.settingsCompany.deleteAccount`) }}
          </div>

          <div>
            <p>
              {{ $t(`retailer.settingsCompany.yourAccountWillBePermanently`) }}
            </p>
            <ButtonElement link @click="isOpen = true" orange-secondary>
              {{ $t(`retailer.settingsCompany.deleteAccount`) }}
            </ButtonElement>
          </div>
        </div>
      </div>
    </div>

    <Modal :open="isOpen" @backdropClick="isOpen = false">
      <ConfirmationModal
        @cancel="isOpen = false"
        @approve="handleDeleteAcc"
        color-approve-button="green"
      >
        <template #header>
          {{ $t(`retailer.settingsCompany.deleteAccount`) }}
        </template>
        <template #default>
          {{ $t(`retailer.settingsCompany.deletingYourAccountWillRemove`) }}
        </template>
      </ConfirmationModal>
    </Modal>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api';
import ButtonElement from '../../../components/FormControl/ButtonElement.vue';
import UserInfo from '../../../components/User/UserInfo.vue';
import Modal from '../../../components/Modal/Modal';
import ConfirmationModal from '../../../components/Modal/ConfirmationModal';
import CompanyProfilePhoto from '../../../components/Company/CompanyProfilePhoto';
import store from '../../../store';
import router from '../../../router';

export default {
  setup() {
    const isOpen = ref(false);
    const handleDeleteAcc = () =>
      store.dispatch('deleteAccount', store.getters.userInfo.id);
    const handleLogout = async () => {
      await store.dispatch('logoutUser');
      router.push({ name: 'Home' });
    };
    return {
      isOpen,
      handleLogout,
      handleDeleteAcc,
    };
  },
  components: {
    CompanyProfilePhoto,
    ButtonElement,
    Modal,
    ConfirmationModal,
    UserInfo,
  },
};
</script>

<style lang="scss">
.company {
  header {
    background-color: $hty-grey;
    padding: 1.375rem 1.5rem;
    display: flex;
    align-items: center;
    border-radius: 0.625rem;
  }

  .company-logo,
  .company-name {
    margin-right: 2.125rem;
  }

  .company-logo {
    position: relative;

    .camera-icon {
      position: absolute;
      bottom: 0;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 2.25rem;
      height: 2.25rem;
      line-height: 2.25rem;
      border-radius: 50%;
      background-color: $hty-medium-grey;
      cursor: pointer;
    }
  }

  .company-name {
    flex: 1 1 100%;
    font-size: 1.25rem;
    color: $hty-orange;
  }

  .logout {
    align-self: flex-end;
  }

  .company-details {
    display: flex;
    flex-flow: row wrap;
    margin: 2.5rem -1.2rem 0;
  }

  .company-details-box {
    flex: 0 0 100%;
    padding: 0 1.25rem;
  }

  .form-control-custom-custom-group {
    margin: 0 -1rem 1rem;
    display: flex;

    .form-control-custom {
      padding: 0 1rem;
    }

    .full-w {
      flex: 1 1 100%;
    }

    .half-w {
      flex: 0 0 50%;
    }
  }

  .grey-box {
    background-color: $hty-grey;
    border-radius: 0.625rem;
    margin-bottom: 1.5rem;
    padding: 0 2rem 2rem;

    .button {
      margin-top: 1rem;
    }
  }

  .title {
    text-align: center;
    padding-top: 0.5rem;
  }
}
</style>
