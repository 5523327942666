<template>
  <div class="services-service">
    <div class="input-section">
      <label>
        {{ $t('serviceProvider.settingsServices.serviceName') }}
      </label>
      <InputElement
        v-model="service_name"
        validation-rules="required"
        validation-name="text"
        :placeholder="$t('serviceProvider.settingsServices.serviceName')"
        @blur="updateForm"
      />
    </div>

    <div class="input-section">
      <label>{{ $t('serviceProvider.settingsServices.category') }}</label>
      <SelectElement
        v-model="category.selected"
        :options="categories"
        validation-rules="required"
        validation-name="text"
      />
    </div>

    <div class="double-section">
      <div class="input-section" style="margin-right: 1rem">
        <label>
          {{ $t('serviceProvider.settingsServices.price') }} (EUR)
        </label>
        <InputElement
          v-model="price"
          type="number"
          validation-rules="required"
          validation-name="text"
          :placeholder="$t('serviceProvider.settingsServices.price')"
          @blur="updateForm"
        />
      </div>
      <div class="input-section" style="margin-left: 1rem">
        <label>
          {{ $t('serviceProvider.settingsServices.duration') }} (Minuten)
        </label>
        <InputElement
          v-model="duration"
          type="number"
          validation-rules="required"
          validation-name="text"
          :placeholder="$t('serviceProvider.settingsServices.duration')"
          @blur="updateForm"
        />
      </div>
    </div>

    <div class="input-section">
      <label>{{ $t('serviceProvider.settingsServices.staff') }}</label>
      <SelectElement
        v-model="staff.selected"
        :options="staffUsers"
        :value="staff.selected"
        multi-select
        search
        validation-rules="required"
        validation-name="text"
        @click="updateForm"
      >
      </SelectElement>
    </div>

    <div class="input-section">
      <label>{{ $t('serviceProvider.settingsServices.description') }}</label>
      <Textarea
        v-model="description"
        validation-rules="required"
        validation-name="text"
        :placeholder="$t('serviceProvider.settingsServices.description')"
        style="height: 140px"
        @blur="updateForm"
      />
    </div>

    <!-- <div
      class="input-section"
      style="display: flex; justify-content: space-between"
    >
      <label>
        {{ $t('serviceProvider.settingsServices.recurringService') }}
      </label>
      <CheckboxElement
        :value="recurring_service"
        @input="() => (recurring_service.value = !recurring_service.value)"
      />
    </div> -->

    <div class="input-section">
      <label>
        {{ $t('serviceProvider.settingsServices.selectPriceType') }}
      </label>
      <div style="display: flex; justify-content: space-between">
        <div
          v-for="(price, i) in price_type.options"
          :key="i"
          style="display: flex"
        >
          <RadioElement
            id="price"
            :radio-value="price.value"
            :name="price.label"
            :value="price_type.selected"
            @input="
              item => {
                price_type.selected = item;
                updateForm();
              }
            "
          >
          </RadioElement>
          <span class="radio-label" style="padding-left: 10px">
            {{ $t(`serviceProvider.settingsServices.${price.key}`) }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import filter from 'lodash/filter';
import store from '@/store';
import InputElement from '@/components/FormControl/InputElement';
import SelectElement from '@/components/Staff/SelectElementCustom';
import Textarea from '@/components/FormControl/TextareaElement';
import RadioElement from '@/components/FormControl/RadioElement';
import CheckboxElement from '@/components/FormControl/CheckboxElement';

export default {
  name: 'ServicesService',

  components: {
    InputElement,
    Textarea,
    RadioElement,
    CheckboxElement,
    SelectElement,
  },

  props: {
    edit: {
      type: [Object, Boolean],
    },
  },

  data() {
    return {
      service_name: '',
      category: {
        selected: '',
      },
      price: '',
      duration: '',
      staff: {
        selected: '',
      },
      description: '',
      // recurring_service: false,
      price_type: {
        label: 'Select price type',
        selected: '',
        options: [
          {
            id: 1,
            label: 'fixed_price',
            value: 'fixed_price',
            key: 'fixedPrice',
          },
          {
            id: 2,
            label: 'price_up_to',
            value: 'price_up_to',
            key: 'priceUpTo',
          },
          {
            id: 3,
            label: 'no_price',
            value: 'no_price',
            key: 'noPrice',
          },
        ],
      },
    };
  },

  created() {
    store.dispatch('fetchCompanyServiceCategories');
    store.dispatch('fetchAllStaffUsers');
    if (this.edit) {
      this.populateData();
      this.updateForm();
    }
  },
  methods: {
    updateForm() {
      let data = {
        name: this.service_name,
        price: this.price,
        duration: this.duration,
        description: this.description,
        // recurring_service: this.recurring_service,
        category_id: this.category.selected.value || this.category.selected,
        price_type: this.price_type.selected,
        staff: this.staff.selected
          ? this.staff.selected.join()
          : this.staff.selected,
      };
      this.$emit('updateForm', data, 'service');
    },
    populateData(data) {
      this.service_name = this.edit.name;
      this.duration = this.edit.duration;
      this.category.selected = {
        value: this.edit.category.id,
        label: this.edit.category.name,
      };
      this.price = this.edit.price;
      this.price_type.selected = this.edit.priceType;
      this.description = this.edit.description;
      this.staff.selected = this.parseStaff(this.edit.staff);
      // this.updateForm();
    },
    formatCategories(categories) {
      const result = [];
      categories.forEach(el => {
        result.push({
          label: el.serviceCategoryName,
          value: el.id,
          subcategoryId: el.subcategoryId,
        });
      });

      return result;
    },
    formatStaffUsers(users) {
      const result = [];
      users.forEach(el => {
        const subcategories = Object.keys(el.subcategories).map(key => {
          return el.subcategories[key].id;
        });
        const subcategory =
          filter(
            this.categories,
            item =>
              item.value === this.category.selected.value ||
              item.value === this.category.selected
          )[0]?.subcategoryId || '';
        if (
          !this.category.selected ||
          subcategories.indexOf(subcategory) >= 0
        ) {
          result.push({
            label: `${el.firstName} ${el.lastName}`,
            value: el.id,
            type: el.type,
          });
        }
      });

      return result;
    },
    parseStaff(users) {
      let ob = [];
      users.forEach(el => {
        ob.push(el.id);
      });
      return ob;
    },
  },
  computed: {
    categories() {
      return this.formatCategories(store.getters.companyServiceCategories);
    },
    staffUsers() {
      return this.formatStaffUsers(store.getters.staffUsers);
    },
  },
  watch: {
    staff: {
      handler() {
        this.updateForm();
      },
      deep: true,
    },
    category: {
      handler() {
        this.updateForm();
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.services-service {
}
.input-section {
  margin-bottom: 22px;

  label {
    display: block;
    margin-bottom: 12px;
    font-weight: 500;
    font-size: 16px;
    color: #e58413;
  }
}

.double-section {
  display: flex;
  // justify-content: space-between;

  .input-section {
    width: 50%;
  }
}
</style>
