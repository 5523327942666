<template>
  <div class="service-gallery">
    <div class="main-image">
      <div class="image-view">
        <div class="image-remove" v-if="selected" @click="removeImage">
          <XIcon />
        </div>
        <img :src="selected" v-if="selected" />
      </div>
    </div>
    <div class="images-list">
      <div
        v-for="(image, i) in images"
        :key="i"
        class="images-list-item"
        @click="selectimage(image)"
      >
        <img class="image" :src="image" />
      </div>
      <div class="images-list-item input" @click="activateInput">
        {{ $t('serviceProvider.settingsServices.addPhoto') }}
      </div>
      <input
        class="file-input"
        ref="input"
        type="file"
        name="resume"
        @change="file"
      />
    </div>
  </div>
</template>

<script>
import store from '@/store';
import XIcon from '@/components/Svgs/XIcon.vue';

export default {
  name: 'ServiceGallery',

  props: {
    edit: {
      type: [Array, Object, Boolean],
    },
  },

  components: {
    XIcon,
  },

  data() {
    return {
      selected: '',
      image: '',
      images: [],
    };
  },

  created() {
    if (this.edit.images) {
      this.images = this.edit.images;
      this.selected = this.images[0];
    }
  },

  methods: {
    file(e) {
      this.images.push(URL.createObjectURL(e.target.files[0]));
      this.$emit('images', e.target.files[0]);

      if (!this.selected) {
        this.selected = this.images[0];
      }
    },
    selectimage(img) {
      this.selected = img;
    },
    removeImage() {
      if (this.edit) {
        const img_name = this.selected.substring(
          this.selected.lastIndexOf('/') + 1,
          this.selected.length
        );
        store
          .dispatch('deleteServiceImage', {
            service: this.edit.id,
            image: img_name,
          })
          .then(() => {
            this.images = this.images.filter(item => item !== this.selected);
            this.selected = this.images[0] || '';
          });
      } else {
        this.images = this.images.filter(item => item !== this.selected);
        this.selected = this.images[0] || '';
        this.$refs['input'].value = '';
      }
    },
    activateInput() {
      this.$refs['input'].click();
    },
  },
};
</script>

<style lang="scss" scoped>
.service-gallery {
}
.main-image {
  position: relative;
  width: 100%;
  height: 416px;
  max-height: 416px;
  border: 1px solid #d0d0d0;
  border-radius: 10px;
}
.image-remove {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  background: #f1f1f1;
  padding: 7px;
  display: flex;
  border-radius: 50%;

  &:hover {
    padding: 8px;
    background: #e8e8e8;
    transition: padding, background 0.2s;
  }
}
.images-list {
  display: flex;
  width: 100%;
  height: 160px;
  margin-top: 20px;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
}
.images-list-item {
  width: 160px;
  height: 160px;
  border-radius: 10px;
  margin-right: 20px;

  &.input {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background: #fff;
    border: 2px dashed #999999;
    box-sizing: border-box;
    border-radius: 10px;
    font-weight: 500;
    font-size: 20px;
    color: #e58413;
    cursor: pointer;
    margin-left: auto;
    margin-right: 0;
  }
}

.file-input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.image,
.image-view img {
  width: 100%;
  height: 100%;
}

.image-view img {
  max-height: 416px;
  object-fit: scale-down;
}

.images-list img {
  object-fit: cover;
}
</style>
